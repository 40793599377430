<template>
  <div class="team-scroller-team-label">
    <div class="team-scroller-team-label__txt font-1">
      {{ value }}
    </div>
  </div>
</template>

<script>
export default {
  name: "TeamScrollerTeamLabel",
  props: {
    value: {
      type: String,
      require: true
    }
  }
}
</script>

<style lang="scss">
.team-scroller-team-label {
  // left: 0;
  // position: absolute;
  width: 40px;
  height: 100%;
  border-left: 1px solid rgba(255, 255, 255, 0.2);

  &__txt {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 1.85em;
    color: #fff;
    white-space: nowrap;
    font-weight: bold;
    transform: translateX(-50%) translateY(-50%) rotate(-90deg);
  }
}
</style>
